const fragmentMap = {
  1: 'black-0',
  202: 'blue-0',
  203: 'blue-0',
  204: 'blue-0',
  205: 'blue-0',
  206: 'blue-0',
  207: 'blue-0',
  208: 'blue-0',
  209: 'blue-0',
  210: 'blue-0',
  211: 'blue-1',
  212: 'blue-1',
  213: 'blue-1',
  214: 'blue-1',
  215: 'blue-1',
  216: 'blue-1',
  217: 'blue-1',
  218: 'blue-1',
  219: 'blue-1',
  220: 'blue-1',
  221: 'blue-2',
  222: 'blue-2',
  223: 'blue-2',
  224: 'blue-2',
  225: 'blue-2',
  226: 'blue-2',
  227: 'blue-2',
  228: 'blue-2',
  229: 'blue-2',
  230: 'blue-2',
  231: 'blue-2',
  232: 'blue-2',
  233: 'blue-2',
  234: 'blue-2',
  235: 'blue-3',
  236: 'blue-3',
  237: 'blue-3',
  238: 'blue-3',
  239: 'blue-3',
  240: 'blue-3',
  241: 'blue-3',
  242: 'blue-3',
  243: 'blue-3',
  244: 'blue-3',
  245: 'blue-3',
  246: 'blue-3',
  247: 'blue-3',
  248: 'blue-3',
  249: 'blue-3',
  250: 'blue-3',
  251: 'blue-4',
  252: 'blue-4',
  253: 'blue-4',
  254: 'blue-4',
  255: 'blue-4',
  256: 'blue-4',
  257: 'blue-4',
  258: 'blue-4',
  259: 'blue-4',
  260: 'blue-4',
  261: 'blue-4',
  262: 'blue-4',
  263: 'blue-4',
  264: 'blue-4',
  265: 'blue-4',
  366: 'blue-5',
  367: 'blue-5',
  368: 'blue-5',
  369: 'blue-5',
  370: 'blue-5',
  371: 'blue-5',
  372: 'blue-5',
  373: 'blue-5',
  374: 'blue-5',
  375: 'blue-5',
  376: 'blue-5',
  377: 'blue-5',
  378: 'blue-6',
  379: 'blue-6',
  380: 'blue-6',
  381: 'blue-6',
  382: 'blue-6',
  383: 'blue-6',
  384: 'blue-6',
  385: 'blue-6',
  386: 'blue-6',
  387: 'blue-6',
  388: 'blue-6',
  389: 'blue-6',
  390: 'blue-6',
  391: 'blue-6',
  392: 'blue-6',
  393: 'blue-6',
  394: 'blue-6',
  533: 'blue-7',
  534: 'blue-7',
  535: 'blue-7',
  536: 'blue-7',
  537: 'blue-7',
  538: 'blue-7',
  539: 'blue-7',
  540: 'blue-7',
  541: 'blue-7',
  542: 'blue-7',
  543: 'blue-7',
  544: 'blue-7',
  545: 'blue-7',
  546: 'blue-7',
  547: 'blue-7',
  731: 'blue-7',
  732: 'blue-7',
  733: 'blue-7',
  734: 'blue-7',
  735: 'blue-7',
  736: 'blue-7',
  737: 'blue-7',
  766: 'blue-7',
  767: 'blue-7',
  768: 'blue-7',
  548: 'blue-8',
  549: 'blue-8',
  550: 'blue-8',
  551: 'blue-8',
  552: 'blue-8',
  553: 'blue-8',
  554: 'blue-8',
  555: 'blue-8',
  556: 'blue-8',
  557: 'blue-8',
  558: 'blue-8',
  559: 'blue-8',
  560: 'blue-8',
  561: 'blue-8',
  562: 'blue-8',
  724: 'blue-8',
  725: 'blue-8',
  726: 'blue-8',
  727: 'blue-8',
  728: 'blue-8',
  729: 'blue-8',
  730: 'blue-8',
  769: 'blue-8',
  770: 'blue-8',
  771: 'blue-8',
  563: 'blue-9',
  564: 'blue-9',
  565: 'blue-9',
  566: 'blue-9',
  567: 'blue-9',
  568: 'blue-9',
  569: 'blue-9',
  570: 'blue-9',
  571: 'blue-9',
  572: 'blue-9',
  573: 'blue-9',
  574: 'blue-9',
  575: 'blue-9',
  576: 'blue-9',
  577: 'blue-9',
  717: 'blue-9',
  718: 'blue-9',
  719: 'blue-9',
  720: 'blue-9',
  721: 'blue-9',
  722: 'blue-9',
  723: 'blue-9',
  772: 'blue-9',
  773: 'blue-9',
  172: 'multi-0',
  173: 'multi-0',
  174: 'multi-0',
  175: 'multi-0',
  176: 'multi-0',
  187: 'multi-0',
  188: 'multi-0',
  189: 'multi-0',
  190: 'multi-0',
  191: 'multi-0',
  177: 'multi-1',
  178: 'multi-1',
  179: 'multi-1',
  180: 'multi-1',
  181: 'multi-1',
  192: 'multi-1',
  193: 'multi-1',
  194: 'multi-1',
  195: 'multi-1',
  196: 'multi-1',
  182: 'multi-2',
  183: 'multi-2',
  184: 'multi-2',
  185: 'multi-2',
  186: 'multi-2',
  197: 'multi-2',
  198: 'multi-2',
  199: 'multi-2',
  200: 'multi-2',
  201: 'multi-2',
  342: 'multi-3',
  343: 'multi-3',
  344: 'multi-3',
  345: 'multi-3',
  346: 'multi-3',
  347: 'multi-3',
  348: 'multi-3',
  349: 'multi-3',
  350: 'multi-3',
  351: 'multi-3',
  352: 'multi-3',
  353: 'multi-3',
  354: 'multi-4',
  355: 'multi-4',
  356: 'multi-4',
  357: 'multi-4',
  358: 'multi-4',
  359: 'multi-4',
  360: 'multi-4',
  361: 'multi-4',
  362: 'multi-4',
  363: 'multi-4',
  364: 'multi-4',
  365: 'multi-4',
  167: 'pink-0',
  168: 'pink-0',
  169: 'pink-0',
  170: 'pink-0',
  171: 'pink-0',
  2: 'pink-1',
  3: 'pink-1',
  4: 'pink-1',
  5: 'pink-1',
  6: 'pink-1',
  7: 'pink-1',
  8: 'pink-1',
  9: 'pink-1',
  10: 'pink-1',
  11: 'pink-1',
  12: 'pink-1',
  13: 'pink-1',
  14: 'pink-1',
  15: 'pink-1',
  16: 'pink-1',
  395: 'pink-1',
  396: 'pink-1',
  397: 'pink-1',
  398: 'pink-1',
  399: 'pink-1',
  400: 'pink-1',
  401: 'pink-1',
  681: 'pink-1',
  682: 'pink-1',
  683: 'pink-1',
  684: 'pink-1',
  685: 'pink-1',
  686: 'pink-1',
  687: 'pink-1',
  688: 'pink-1',
  689: 'pink-1',
  690: 'pink-1',
  691: 'pink-1',
  692: 'pink-1',
  693: 'pink-1',
  694: 'pink-1',
  695: 'pink-1',
  17: 'pink-2',
  18: 'pink-2',
  19: 'pink-2',
  20: 'pink-2',
  21: 'pink-2',
  22: 'pink-2',
  23: 'pink-2',
  24: 'pink-2',
  25: 'pink-2',
  26: 'pink-2',
  27: 'pink-2',
  28: 'pink-2',
  29: 'pink-2',
  30: 'pink-2',
  31: 'pink-2',
  402: 'pink-2',
  403: 'pink-2',
  404: 'pink-2',
  405: 'pink-2',
  406: 'pink-2',
  407: 'pink-2',
  408: 'pink-2',
  32: 'pink-3',
  33: 'pink-3',
  34: 'pink-3',
  35: 'pink-3',
  36: 'pink-3',
  37: 'pink-3',
  38: 'pink-3',
  39: 'pink-3',
  40: 'pink-3',
  41: 'pink-3',
  42: 'pink-3',
  43: 'pink-3',
  44: 'pink-3',
  45: 'pink-3',
  46: 'pink-3',
  409: 'pink-3',
  410: 'pink-3',
  411: 'pink-3',
  412: 'pink-3',
  413: 'pink-3',
  414: 'pink-3',
  415: 'pink-3',
  47: 'pink-4',
  48: 'pink-4',
  49: 'pink-4',
  50: 'pink-4',
  51: 'pink-4',
  52: 'pink-4',
  53: 'pink-4',
  54: 'pink-4',
  55: 'pink-4',
  56: 'pink-4',
  57: 'pink-4',
  58: 'pink-4',
  59: 'pink-4',
  60: 'pink-4',
  61: 'pink-4',
  416: 'pink-4',
  417: 'pink-4',
  418: 'pink-4',
  419: 'pink-4',
  420: 'pink-4',
  421: 'pink-4',
  422: 'pink-4',
  423: 'pink-4',
  424: 'pink-4',
  425: 'pink-4',
  62: 'pink-5',
  63: 'pink-5',
  64: 'pink-5',
  65: 'pink-5',
  66: 'pink-5',
  67: 'pink-5',
  68: 'pink-5',
  69: 'pink-5',
  70: 'pink-5',
  71: 'pink-5',
  72: 'pink-5',
  73: 'pink-5',
  74: 'pink-5',
  75: 'pink-5',
  76: 'pink-5',
  77: 'pink-6',
  78: 'pink-6',
  79: 'pink-6',
  80: 'pink-6',
  81: 'pink-6',
  82: 'pink-6',
  83: 'pink-6',
  84: 'pink-6',
  85: 'pink-6',
  86: 'pink-6',
  87: 'pink-6',
  88: 'pink-6',
  89: 'pink-6',
  90: 'pink-6',
  91: 'pink-6',
  92: 'pink-7',
  93: 'pink-7',
  94: 'pink-7',
  95: 'pink-7',
  96: 'pink-7',
  97: 'pink-7',
  98: 'pink-7',
  99: 'pink-7',
  100: 'pink-7',
  101: 'pink-7',
  102: 'pink-7',
  103: 'pink-7',
  104: 'pink-7',
  105: 'pink-7',
  106: 'pink-7',
  107: 'pink-8',
  108: 'pink-8',
  109: 'pink-8',
  110: 'pink-8',
  111: 'pink-8',
  112: 'pink-8',
  113: 'pink-8',
  114: 'pink-8',
  115: 'pink-8',
  116: 'pink-8',
  117: 'pink-8',
  118: 'pink-8',
  119: 'pink-8',
  120: 'pink-8',
  121: 'pink-8',
  122: 'pink-9',
  123: 'pink-9',
  124: 'pink-9',
  125: 'pink-9',
  126: 'pink-9',
  132: 'pink-9',
  133: 'pink-9',
  134: 'pink-9',
  135: 'pink-9',
  136: 'pink-9',
  518: 'pink-9',
  519: 'pink-9',
  520: 'pink-9',
  521: 'pink-9',
  522: 'pink-9',
  523: 'pink-9',
  524: 'pink-9',
  525: 'pink-9',
  526: 'pink-9',
  527: 'pink-9',
  528: 'pink-9',
  529: 'pink-9',
  530: 'pink-9',
  531: 'pink-9',
  532: 'pink-9',
  710: 'pink-9',
  711: 'pink-9',
  712: 'pink-9',
  713: 'pink-9',
  714: 'pink-9',
  715: 'pink-9',
  716: 'pink-9',
  774: 'pink-9',
  775: 'pink-9',
  127: 'pink-10',
  128: 'pink-10',
  129: 'pink-10',
  130: 'pink-10',
  131: 'pink-10',
  137: 'pink-11',
  138: 'pink-11',
  139: 'pink-11',
  140: 'pink-11',
  141: 'pink-11',
  142: 'pink-12',
  143: 'pink-12',
  144: 'pink-12',
  145: 'pink-12',
  146: 'pink-12',
  147: 'pink-13',
  148: 'pink-13',
  149: 'pink-13',
  150: 'pink-13',
  151: 'pink-13',
  487: 'pink-13',
  488: 'pink-13',
  489: 'pink-13',
  490: 'pink-13',
  491: 'pink-13',
  492: 'pink-13',
  493: 'pink-13',
  494: 'pink-13',
  495: 'pink-13',
  496: 'pink-13',
  497: 'pink-13',
  498: 'pink-13',
  499: 'pink-13',
  500: 'pink-13',
  501: 'pink-13',
  696: 'pink-13',
  697: 'pink-13',
  698: 'pink-13',
  699: 'pink-13',
  700: 'pink-13',
  701: 'pink-13',
  702: 'pink-13',
  777: 'pink-13',
  152: 'pink-14',
  153: 'pink-14',
  154: 'pink-14',
  155: 'pink-14',
  156: 'pink-14',
  157: 'pink-15',
  158: 'pink-15',
  159: 'pink-15',
  160: 'pink-15',
  161: 'pink-15',
  162: 'pink-16',
  163: 'pink-16',
  164: 'pink-16',
  165: 'pink-16',
  166: 'pink-16',
  502: 'pink-16',
  503: 'pink-16',
  504: 'pink-16',
  505: 'pink-16',
  506: 'pink-16',
  507: 'pink-16',
  508: 'pink-16',
  509: 'pink-16',
  510: 'pink-16',
  511: 'pink-16',
  512: 'pink-16',
  513: 'pink-16',
  514: 'pink-16',
  515: 'pink-16',
  516: 'pink-16',
  517: 'pink-16',
  703: 'pink-16',
  704: 'pink-16',
  705: 'pink-16',
  706: 'pink-16',
  707: 'pink-16',
  708: 'pink-16',
  709: 'pink-16',
  776: 'pink-16',
  266: 'white-0',
  267: 'white-0',
  268: 'white-0',
  269: 'white-0',
  270: 'white-0',
  271: 'white-0',
  272: 'white-0',
  273: 'white-0',
  274: 'white-0',
  275: 'white-0',
  276: 'white-0',
  277: 'white-0',
  278: 'white-0',
  279: 'white-0',
  280: 'white-0',
  281: 'white-0',
  426: 'white-1',
  427: 'white-1',
  428: 'white-1',
  429: 'white-1',
  430: 'white-1',
  431: 'white-1',
  432: 'white-1',
  433: 'white-1',
  434: 'white-1',
  435: 'white-1',
  436: 'white-1',
  437: 'white-1',
  438: 'white-1',
  439: 'white-1',
  440: 'white-1',
  472: 'white-1',
  473: 'white-1',
  474: 'white-1',
  475: 'white-1',
  476: 'white-1',
  477: 'white-1',
  478: 'white-1',
  479: 'white-1',
  480: 'white-1',
  481: 'white-1',
  482: 'white-1',
  483: 'white-1',
  484: 'white-1',
  485: 'white-1',
  486: 'white-1',
  441: 'white-2',
  442: 'white-2',
  443: 'white-2',
  444: 'white-2',
  445: 'white-2',
  446: 'white-2',
  447: 'white-2',
  448: 'white-2',
  449: 'white-2',
  450: 'white-2',
  451: 'white-2',
  452: 'white-2',
  453: 'white-2',
  454: 'white-2',
  455: 'white-2',
  456: 'white-2',
  457: 'white-3',
  458: 'white-3',
  459: 'white-3',
  460: 'white-3',
  461: 'white-3',
  462: 'white-3',
  463: 'white-3',
  464: 'white-3',
  465: 'white-3',
  466: 'white-3',
  467: 'white-3',
  468: 'white-3',
  469: 'white-3',
  470: 'white-3',
  471: 'white-3',
  578: 'white-4',
  579: 'white-4',
  580: 'white-4',
  581: 'white-4',
  582: 'white-4',
  583: 'white-4',
  584: 'white-4',
  585: 'white-4',
  586: 'white-4',
  587: 'white-4',
  588: 'white-4',
  589: 'white-4',
  590: 'white-4',
  591: 'white-4',
  592: 'white-4',
  738: 'white-4',
  739: 'white-4',
  740: 'white-4',
  741: 'white-4',
  742: 'white-4',
  743: 'white-4',
  744: 'white-4',
  763: 'white-4',
  764: 'white-4',
  765: 'white-4',
  593: 'white-5',
  594: 'white-5',
  595: 'white-5',
  596: 'white-5',
  597: 'white-5',
  598: 'white-5',
  599: 'white-5',
  600: 'white-5',
  601: 'white-5',
  602: 'white-5',
  603: 'white-5',
  604: 'white-5',
  605: 'white-5',
  606: 'white-5',
  607: 'white-5',
  745: 'white-5',
  746: 'white-5',
  747: 'white-5',
  748: 'white-5',
  749: 'white-5',
  750: 'white-5',
  751: 'white-5',
  759: 'white-5',
  760: 'white-5',
  761: 'white-5',
  762: 'white-5',
  608: 'white-6',
  609: 'white-6',
  610: 'white-6',
  611: 'white-6',
  612: 'white-6',
  613: 'white-6',
  614: 'white-6',
  615: 'white-6',
  616: 'white-6',
  617: 'white-6',
  618: 'white-6',
  619: 'white-6',
  620: 'white-6',
  621: 'white-6',
  622: 'white-6',
  752: 'white-6',
  753: 'white-6',
  754: 'white-6',
  755: 'white-6',
  756: 'white-6',
  757: 'white-6',
  758: 'white-6',
  306: 'yellow-0',
  307: 'yellow-0',
  308: 'yellow-0',
  309: 'yellow-0',
  310: 'yellow-0',
  311: 'yellow-0',
  312: 'yellow-0',
  313: 'yellow-0',
  314: 'yellow-0',
  315: 'yellow-0',
  316: 'yellow-0',
  317: 'yellow-0',
  282: 'yellow-1',
  283: 'yellow-1',
  284: 'yellow-1',
  285: 'yellow-1',
  286: 'yellow-1',
  287: 'yellow-1',
  288: 'yellow-1',
  289: 'yellow-1',
  290: 'yellow-1',
  291: 'yellow-1',
  292: 'yellow-1',
  293: 'yellow-1',
  633: 'yellow-1',
  634: 'yellow-1',
  635: 'yellow-1',
  636: 'yellow-1',
  637: 'yellow-1',
  638: 'yellow-1',
  639: 'yellow-1',
  640: 'yellow-1',
  641: 'yellow-1',
  642: 'yellow-1',
  294: 'yellow-2',
  295: 'yellow-2',
  296: 'yellow-2',
  297: 'yellow-2',
  298: 'yellow-2',
  299: 'yellow-2',
  300: 'yellow-2',
  301: 'yellow-2',
  302: 'yellow-2',
  303: 'yellow-2',
  304: 'yellow-2',
  305: 'yellow-2',
  643: 'yellow-2',
  644: 'yellow-2',
  645: 'yellow-2',
  646: 'yellow-2',
  647: 'yellow-2',
  648: 'yellow-2',
  649: 'yellow-2',
  650: 'yellow-2',
  651: 'yellow-2',
  652: 'yellow-2',
  663: 'yellow-2',
  664: 'yellow-2',
  665: 'yellow-2',
  666: 'yellow-2',
  667: 'yellow-2',
  668: 'yellow-2',
  669: 'yellow-2',
  670: 'yellow-2',
  671: 'yellow-2',
  672: 'yellow-2',
  673: 'yellow-2',
  674: 'yellow-2',
  675: 'yellow-2',
  676: 'yellow-2',
  677: 'yellow-2',
  678: 'yellow-2',
  679: 'yellow-2',
  680: 'yellow-2',
  318: 'yellow-3',
  319: 'yellow-3',
  320: 'yellow-3',
  321: 'yellow-3',
  322: 'yellow-3',
  323: 'yellow-3',
  324: 'yellow-3',
  325: 'yellow-3',
  326: 'yellow-3',
  327: 'yellow-3',
  328: 'yellow-3',
  329: 'yellow-3',
  330: 'yellow-4',
  331: 'yellow-4',
  332: 'yellow-4',
  333: 'yellow-4',
  334: 'yellow-4',
  335: 'yellow-4',
  336: 'yellow-4',
  337: 'yellow-4',
  338: 'yellow-4',
  339: 'yellow-4',
  340: 'yellow-4',
  341: 'yellow-4',
  623: 'yellow-5',
  624: 'yellow-5',
  625: 'yellow-5',
  626: 'yellow-5',
  627: 'yellow-5',
  628: 'yellow-5',
  629: 'yellow-5',
  630: 'yellow-5',
  631: 'yellow-5',
  632: 'yellow-5',
  653: 'yellow-5',
  654: 'yellow-5',
  655: 'yellow-5',
  656: 'yellow-5',
  657: 'yellow-5',
  658: 'yellow-5',
  659: 'yellow-5',
  660: 'yellow-5',
  661: 'yellow-5',
  662: 'yellow-5',
}

export default fragmentMap
